import React, {useState, useCallback} from 'react';
import ButtonIncrement from '../components/common/ButtonIncrement';
import face from './../assets/img/icons/face_blue.png';
import twwit from './../assets/img/icons/twwit_blue.png';
import email from './../assets/img/icons/mail_orange.png'; 

const CardItemDetail = ({ product, handleDelete, cart, setCart }) => {
    const [count, setCount] = useState(product.count);

    let products = cart.products;

    const handleChangeCount = useCallback(val => {
        setCount(val)
        for(const element of products) {
            if(element.productId === product.productId) element.count = val; 
        }
        setCart({
            ...cart,
            products
        })
    },[setCount,setCart,cart, products, product])

    const textDummy = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer porttitor quam quam, 
    eget pretium sapien feugiat non.`
    return ( 
        <div className="card_initial row w-100 m-0 p-3">
            <div className="col-md-12 col-lg-3">
                <div className="content text-left">
                    <h4>{product.productName}</h4>
                    <p></p>
                </div>
            </div>
            <div className="col-md-5 col-lg-3">
                <div className="content_initial_cart_image d-flex align-items-center mx-auto">
                    <div className="image_content">
                        <img src={product.productImage} alt={product.productName} />
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-lg-3">
                <div className="content text-left">
                    <div className="underline ml-0"></div>
                    <label>Precio</label>
                    <h3 className="price">${product.price.toFixed(2)}</h3>
                    <ButtonIncrement actual={count} handleChangeCount={handleChangeCount} />
                    <div className="underline ml-0 mt-4"></div>
                    <label className="">Descripción</label>
                    <p>{product.productDescription || textDummy }</p>
                </div>
            </div>
            <div className="col-md-3 d-flex align-items-end position-relative">
                <div className="delete_product_cart_initial" onClick={() => handleDelete(product)}>Eliminar</div>
                <div className="content">
                    <div className="underline ml-0"></div>
                    <label>Comparte y comenta</label>
                    <div className="d-flex align-items-center">
                        <a href="https://www.addtoany.com/add_to/facebook?linkurl=https%3A%2F%2Fsandbox-sf-api.bankaya.com.mx%2F&amp;linkname=Chedraui%20-%20Servicios%20Financieros" className="mr-2" target="_blank" rel="noopener noreferrer">
                            <img src={face} alt="face" />
                        </a>
                        <a href="https://www.addtoany.com/add_to/twitter?linkurl=https%3A%2F%2Fsandbox-sf-api.bankaya.com.mx%2F&amp;linkname=Chedraui%20-%20Servicios%20Financieros" className="mr-2" target="_blank" rel="noopener noreferrer">
                            <img src={twwit} alt="twwit" />
                        </a>
                        <a href="https://www.addtoany.com/add_to/email?linkurl=https%3A%2F%2Fsandbox-sf-api.bankaya.com.mx%2F&amp;linkname=Chedraui%20-%20Servicios%20Financieros" className="mr-2" target="_blank" rel="noopener noreferrer">
                            <img src={email} alt="mail" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default CardItemDetail;