import styled from 'styled-components'; 
import { LinkComponent } from './common';

export const Button = styled.button`
    background-color: ${props => props.bgColor || '#0D47A1'};
    padding: ${props => props.p || '10px 20px'};
    width: ${props => props.widthComponent || '100%' };
    border-radius: 5px; 

`;
export const ButtonLink = styled(LinkComponent)`
    background-color:  ${props => props.bgColor || '#0D47A1'}; 
    color: ${props => props.colorText || '#fff'};
    box-sizing: border-box; 
    border: none;
    padding: 8px;
    border-radius: 5px;
    height: 47px;
    font-size: 18px;
    text-align: center;
`;

export const ButtonSubmit = styled(ButtonLink)`
    position: relative;
`;

export const Div = styled.div`
    text-align: center; 
    margin-top: ${props => props.mt || '3rem'};
    padding: ${props => props.p || '0'};
    background-color: ${props => props.bgColor || 'transparent'};
    @media (max-width: 767px) {
        margin-top: ${props => props.mt_xs || '3rem'};
        padding: ${props => props.p_xs || '0'};
    }
`;

export const DivBackgroundImage = styled.div`
    width: ${props => props.widthComponent || '100%'};
    height: ${props => props.heightComponent || '450px'};
    background-position: center; 
    background-size: ${props => props.bgSize || 'cover'}; 
    background-repeat: no-repeat; 
    background-image: url('${props => props.bgImage || '100%'}');
    @media (max-width: 767px) {
        height: ${props => props.heightComponentXs || '210px'};
    }
`;

export const InputComponent = styled.input`
    width: 100%; 
    height: 40px; 
    border-radius: 12px; 
`;

export const ImgComponent = styled.img`
    width: ${props => props.widthComponent || '100%'};
    height: ${props => props.heightComponent || 'auto'};
`;

export const ParagraphOne = styled.p`
    color: ${props => props.textColor || '1rem'};
`;

export const CardComponent = styled.div`
    padding: ${props => props.p || "2rem 1rem"}; 
    background-color: ${props => props.bgColor || '#fff'};
    border-radius: 10px;
    transition: all .45s;
    -webkit-box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.16);
    box-shadow: 0px 0px 14px 4px rgba(0,0,0,0.16);
    &:hover {
        background: rgba(244,125,0,1);
        background: -moz-linear-gradient(45deg, rgba(244,125,0,1) 0%, rgba(255,175,75,1) 100%);
        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(244,125,0,1)), color-stop(100%, rgba(255,175,75,1)));
        background: -webkit-linear-gradient(45deg, rgba(244,125,0,1) 0%, rgba(255,175,75,1) 100%);
        background: -o-linear-gradient(45deg, rgba(244,125,0,1) 0%, rgba(255,175,75,1) 100%);
        background: -ms-linear-gradient(45deg, rgba(244,125,0,1) 0%, rgba(255,175,75,1) 100%);
        background: linear-gradient(45deg, rgba(244,125,0,1) 0%, rgba(255,175,75,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f47d00', endColorstr='#ffaf4b', GradientType=1 );
        transform: scale(1.15);
    }
    @media (max-width: 1699px) {
        padding: 2rem 1rem;
    }
`;