import React from 'react';
import face from './../assets/img/icons/facebookLogo.png'; 
import instagram from './../assets/img/icons/instagramLogo.png'
const menu = ['Tiempo Aire', 'Pago de servicios', 'Seguro de Auto', 'Seguro de Moto','Programa de Asistencia','Tarjeta de regalo', 'venta de celular o crédito']
const Footer = () => (
    <div className="container-fluid">
        <div id="footer" className="d-flex flex-wrap w-100">
            <div className="col-md-2 d-flex justify-content-left align-items-center">
                <p className="font-weight-bold">Grupo Chedraui</p>
            </div>
            <div className="col-md-8">
                <div className="nav_footer d-flex flex-wrap justify-content-center">
                    {menu.map((item, indx) => (
                        <p key={indx} className="footer_item mx-0 px-3">{item}</p>   
                    ))}       
                </div>
            </div>
            <div className="col-md-2">
                <div className="icons_footer text-left text-md-center ml-3 ml-md-0 mt-3">
                    <a href="https://www.facebook.com/CHEDRAUIOFICIAL/" target="_blank" rel="noopener noreferrer">
                        <img src={face} className="img-fluid" alt="face" />
                    </a>
                    <a href="https://www.instagram.com/chedraui_" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} className="ml-2 img-fluid" alt="instagram" />
                    </a>
                </div>
            </div>
            <div className="col-md-12 ml-3 ml-md-0 my-3">
                <p className="text-left">Centro de atención telefónica: 800 - 563 - 2222 © 2020 Grupo Chedraui. Todos los derechos reservados</p>
            </div>
        </div>
    </div>
);
 
export default Footer;