import { ApolloClient, InMemoryCache } from '@apollo/client'; 
import { RestLink } from 'apollo-link-rest';

// setup your `RestLink` with your endpoint
const restLink = new RestLink({ 
  uri: "https://sandbox-sf-api.bankaya.com.mx/api/v1/",
  //uri: "http://localhost:49160/api/v1/",

  headers: {
    'Authorization': 'Basic YWRtaW46c3VwZXJzZWNyZXQ=',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
    'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS'
  },
  credentials: "same-origin",
});

// setup your client
const client = new ApolloClient({
  link: restLink,
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  }
});

export default client; 