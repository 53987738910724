import React from 'react';
import { Link } from 'react-router-dom'; 
import { CardComponent } from './index.styled';

const Card = ({title, textBold, img, to, className}) => (
    <Link to={to} className={`service_element ${className}`}>
        <CardComponent p="2.5rem 2rem" className="my-3 card">
            <div className="text-center">
                <div className="service_image"></div>
            </div>
            <p className="text_one">{title} <span>{textBold}</span></p>
        </CardComponent>
    </Link>
)   
 
export default Card;