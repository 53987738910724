import React, { Fragment } from 'react';
import { Div, DivBackgroundImage, ImgComponent } from './../components/common/index.styled';
import Features from './../components/Features';
import img1 from './../assets/img/img1.png';
import home2 from './../assets/img/home2.png';
import img4 from './../assets/img/img4.png';
import img5 from './../assets/img/img5.png';
import img62 from './../assets/img/img62.jpg';
import img_slider from './../assets/img/img3.png';
import Card from './../components/common/Card';
import ChatFloat from './../components/ChatFloat' 
import Carousel from '../components/Carousel';
import logoBankaya from './../assets/img/logoBankaya.png'
import logoCasasAra from './../assets/img/logoCasasAra.png'
import logoCdmx from './../assets/img/logoCdmx.png'
import logoCfe from './../assets/img/logoCfe.png'
import logoCsb from './../assets/img/logoCsb.png'
import logoDish from './../assets/img/logoDish.png'
import logoFarmaciasSimilares from './../assets/img/logoFarmaciasSimilares.png'
import logoIzzi from './../assets/img/logoIzzi.png'
import logoNaucalpan from './../assets/img/logoNaucalpan.png'
import logoOriflame from './../assets/img/logoOriflame.png'
import logoSky from './../assets/img/logoSky.png'
import logoTelmex from './../assets/img/logoTelmex.png'
import logoTupperware from './../assets/img/logoTupperware.png'
import feature1 from './../assets/img/logoBannerCitibanamex.png';
import feature2 from './../assets/img/logoBannerPaypal.png';
import feature3 from './../assets/img/logoBannerTarjeta.png';
import feature4 from './../assets/img/logoBannerSantander.png';
import next from './../assets/img/icons/next.png';

const clickNext = () => {
    let arroNext = document.querySelector('.slick-next'); 
    arroNext.click(); 
}

const BannerPrincipal = () => (
    <div id="home_principal" className="container-fluid position-relative">
        <div className="next_arrow_content" onClick={clickNext}>
            <img src={next}  alt="next"/>
        </div>
        <Carousel>
            <div className="position-relative">
                <DivBackgroundImage id="bgImageHome" bgImage={img1} heightComponent="430px" />
                <div className="content_float text-left">
                    <div className="content position-relative">
                        <div className="next_arrow">
                            <h2>Paga tu <br></br><span className="bigger_title">Seguro de auto</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-relative">
                <DivBackgroundImage bgImage={home2} heightComponent="430px" />
                <div className="content_float text-left">
                    <div className="content position-relative">
                        
                    </div>
                </div>
            </div>
        </Carousel>
        <Div className="m-0 w-100">
            {<Features 
                features={[
                    { title: "3 MSI con Tarjeta de crédito Citibanamex", col: "col-6 col-lg", img: feature1 },
                    { title: "Cupón de $250 y hasta 12 Meses sin intereses con Paypal", col: "col-6 col-lg-3", img: feature2},
                    { title: "6 o 12 MSI con Tarjetas de crédito participantes", col: "col-6 col-lg", img: feature3}, 
                    { title: "Compra en una sola exhibición y difiere tu compra a 3MSI con Tarjeta de Crédito", col: "col-6 col-lg-4", img: feature4 }
                ]}
            />}
        </Div>
    </div>
)

const SecondaryBanner = () => (
    <div id="second_banner_home">
        <div className="container">
            <div className="content position-relative">
                <h2 className="text-left">Paga todos tus servicios de forma fácil y segura</h2>
                <p className="paragraph_one text-left">Selecciona el servicio que deseas pagar</p>
            </div>
        </div>
        <Div p="1rem" className="col-md-11 col-lg-9 col-xl-8 m-auto d-flex justify-content-center align-items-center flex-wrap">
            <Card className="card_content" title='Tiempo' textBold="Aire" to="/checkout" />
            <Card className="card_content" title='Pago de' textBold="Servicios" to="/checkout" />
            <Card className="card_content" title='Tarjetas' textBold="digitales" to="/gift_cards" />
            <Card className="card_content" title='Celular a' textBold="Credito" to="/checkout" />
            <Card className="card_content" title='Banca' textBold="Digital" to="/checkout" />
            <Card className="card_content" title='Seguros' textBold="Automotrices" to="/checkout" />
            <Card className="card_content" title='Programas de' textBold="Asistencias" to="/checkout" />
            <Card className="card_content" title='Remesas' textBold="" to="/checkout" />
            <Card className="card_content" title='Otros' textBold="Servicios" to="/checkout" />
        </Div>
    </div>
)

const SliderTwo = () => (
    <Div id="slider_two" p="0 20px">
        <Carousel>
            <div className="position-relative d-flex justify-content-end flex-wrap">
                <Div bgColor="#0D47A1" className="position-absolute">
                    <div className="content">
                        <h2>Beneficios</h2>
                        <p className="paragraph_one">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                        labore et dolore magna aliqua. Ut enim ad inim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea</p>
                    </div>
                </Div>
                <ImgComponent widthComponent="75%" src={img_slider} />
            </div>
            <div className="position-relative d-flex justify-content-end flex-wrap">
                <Div bgColor="#0D47A1" className="position-absolute">
                    <div className="content">
                        <h2>Beneficios</h2>
                        <p className="paragraph_one">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut 
                        labore et dolore magna aliqua. Ut enim ad inim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea</p>
                    </div>
                </Div>
                <ImgComponent widthComponent="75%" src={img_slider} />
            </div>
        </Carousel>
    </Div>
); 
const partners = [logoBankaya,logoCfe, logoIzzi, logoNaucalpan, logoTelmex,logoSky,logoCsb, logoDish,logoFarmaciasSimilares,
    logoTupperware, logoOriflame,logoCasasAra,logoCdmx]; 
const Links = ['https://www.bankaya.com.mx','https://www.cfe.mx/Pages/Index.aspx','https://www.izzi.mx/home', 'https://www.naucalpan.gob.mx','https://www.telmex.com.mx','https://www.sky.com.mx','https://www.consubanco.com','https://www.dish.com.mx','https://www.farmaciassimilares.com','https://www.tupperware.com.mx','https://www.mx.oriflame.com','https://www.ara.com.mx','https://www.gob.mx']
export const Home = () => (
    <Fragment>
        <ChatFloat />
        <BannerPrincipal />
        <SecondaryBanner />
        <SliderTwo />
        <Div className="col-lg-9 mx-auto" id="banner4" mt="0" mt_xs="0">
            <div className="d-flex align-items-center flex-wrap position-relative">
                <DivBackgroundImage id="bgImageHome2" bgImage={img4} widthComponent="50%" heightComponent="530px" />
                <div className="col-md-6 h-100">
                    <div className="content">
                        <h2 className="text-left">Servicios Financieros</h2>
                        <p className="paragraph_one text-left">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim</p>
                    </div>
                </div>
            </div>
        </Div>
        <Div id="banner_comunity_home" mt="8rem" mt_xs="1rem" className="position-relative">
            <DivBackgroundImage bgImage={img5} heightComponent="600px" heightComponentXs="200px" />
            <br></br>
            <h2 className="text-center">Comunidad</h2>
            <p  className="paragraph_one text-center mt-4 mt-md-5 mx-auto px-3">consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
        </Div>
        <Div className="row w-100 d-flex align-items-center mx-0" bgColor="#0D47A1">
            <DivBackgroundImage className="col-lg-4" bgImage={img62} heightComponentXs="300px" heightComponent="350px" />
            <Div className="col-lg-8 m-0 p-4 p-md-5">
                <Div p="0 1rem" p_xs="0" className="content m-0 text-left text_light">
                    <h2>Ventas corporativas</h2>
                    <p className="paragraph_one">consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.</p>
                </Div>
            </Div>
        </Div>
        <Div id="clients_banner" className="container text-center position-relative mb-5">
            <h2 className="text-center">Nuestros Aliados</h2>
            <Div className="col-md-11 col-lg-11 m-auto d-flex justify-content-center align-items-center flex-wrap">
                {partners.map((partner, index) => (
                    <a href={Links[index]} key={index} target="_blank"  rel="noopener noreferrer">
                        <ImgComponent key={index} className="aliados-content" src={partner} alt={`partner-${index}`} />
                    </a>
                ))}
            </Div>
        </Div>
    </Fragment>
)
