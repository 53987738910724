import React, { useState, useEffect, useContext, useCallback } from 'react'; 

import { CartContext } from './../context/contextCart'; 
import imgDefault from './../assets/img/default.png';
import iconCart from './../assets/img/icons/iconCart.png';
import { Div, InputComponent } from './../components/common/index.styled';
import { deleteElement, elementExist, getPrice } from './../helpers';
import ButtonIncrement from './../components/common/ButtonIncrement';

const GiftCard = ({ card }) => {
    const [buttonAdCard, setButtonAddCard] = useState(true);
    const [count, setCount] = useState(1);
    const { cart, setCart } = useContext(CartContext);
    const { isVariableValue, baseValueAmount, maxValueAmount } = card.details.activationCharacteristics ;
    const { productName, currency } = card.summary;
    const { activationCharacteristics, productDescription, productConfigurations } = card.details; 

    let productsAdded = cart.products.length === 0 ? JSON.parse(localStorage.getItem('cartProducts')).products || [] : cart.products; 
    let img = productConfigurations.length !== 0 ? (productConfigurations[0].productImages.length !== 0) ? card.details.productConfigurations[0].productImages[0].frontImage
    : imgDefault : imgDefault; 
    let id = productConfigurations.length > 0 ? productConfigurations[0].configurationId : '';
    let productId = card.entityId.split('/product/')[1];

    const [giftCard,setGiftCard] = useState({
        productName,
        productDescription: productDescription,
        productImage: img,
        currency,
        productId,
        productConfigurationId: id,
        price: !elementExist(productsAdded, productId, "productId") ? baseValueAmount : getPrice(productsAdded, productId,"productId"),
        activationCharacteristics,
        provider: "Blackhawk",
        type: "gitf_card",
        count
    })

    const handleAddCart = val => {
        setButtonAddCard(val); 
        if(val) {
            productsAdded = deleteElement(productsAdded, giftCard.productId, "productId");
            console.log("Filtrado", productsAdded)
        } else {
            let flag = false; 
            productsAdded.map(product => {
                if(product.productId === giftCard.productId) {
                    product.count += count; 
                    flag = true; 
                }
                return product
            })
            if(!flag) {
                productsAdded.push({...giftCard, count}); 
            }            
        }
        setCart({
            count: productsAdded.length, 
            products: productsAdded 
        })
    }

    const handleChangeCount = useCallback(val => {
        setCount(val)
        for(const element of productsAdded) {
            if(element.productId === giftCard.productId) element.count = val; 
        }
        setCart({
            ...cart,
            products: productsAdded
        })
    },[setCount,setCart,cart, productsAdded, giftCard]);

    const handleChangePrice = (e,max) => {
        let { value } = e.target;
        if(isNaN(Number(value.split("$")[1]))) return;
        if(Number(value.split("$")[1]) > max) {
            value = '$' + max; 
        }
        setGiftCard({ ...giftCard, price: Number(value.split("$")[1])})
    }
    
    const handleBlur = (e, min) => {
        let { value } = e.target;
        console.log(value,min)
        if(Number(value.split("$")[1]) < min) {
            value = '$' + min;    
        }
        setGiftCard({ ...giftCard, price: Number(value.split("$")[1])})
    }

    useEffect(() => {
        let elementFind = elementExist(productsAdded, giftCard.productId, "productId");
        if(elementFind) setCount(elementFind.count);
    }, [setCount,giftCard,productsAdded])

    const addCart = buttonAdCard && !elementExist(productsAdded, giftCard.productId, "productId") ? <button onClick={() => handleAddCart(false)} className="btn secondary_button">Agregar</button> 
    : <button onClick={() => handleAddCart(true)} className="btn secondary_button_success w-100">Agregado</button> 
    
    const price = (!isVariableValue) ? <p className="price my-3">${card.details.activationCharacteristics.baseValueAmount}</p>
    : <InputComponent 
        type="text" 
        value={`$${giftCard.price}`} 
        name="price" className="form-control my-2 text-center" 
        placeholder="Precio" 
        onChange={ e => handleChangePrice(e,maxValueAmount) } 
        onBlur ={ e => handleBlur(e, baseValueAmount) }
        disabled={buttonAdCard && !elementExist(productsAdded, giftCard.productId, "productId") ? null : 'disabled'}
        />
    return(
        <div className="col-6 col-lg-4 my-3 gift_card_item">
            <div className="content m-0 m-md-2 card px-md-4 px-lg-4 px-xl-5 py-md-4 p-3">
                <Div className="image_content m-0 d-flex justify-content-center align-items-center w-100">   
                    <img src={ img } alt={card.summary.productName} className="img-fluid" /> 
                </Div>
                <div>
                    <p className="product_name">{ card.summary.productName}</p>
                    {price}
                    <ButtonIncrement handleChangeCount={handleChangeCount} actual={count} />
                    <div className="position-relative button_add_cart mt-2">
                        <img src={iconCart} className="img-fluid" alt="cart_icon" />
                        {addCart}
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default GiftCard;