import React, { Fragment } from "react";

export const OpenPay = window.OpenPay;
OpenPay.setId("msuyx5nqnarczqpupneb");
OpenPay.setApiKey("pk_30db68394f3c4d59ae44139322c96c6a"); //pk_0ad96a476e864515bae4ce644b39c379
OpenPay.setSandboxMode(true);

export const randomWord = () => {
    let charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < 12; i++) {
        let randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz,randomPoz+1);
    }
    return randomString;
}

export const deleteElement = (data, id, attribute) => data.filter(element => element[attribute] !== id); 

export const elementExist = (data, id, attribute) => {
    let elementFind = data.find(element => element[attribute] === id)
    return elementFind === undefined ? false : elementFind;
}

export const getPrice = (data, id, attribute) => {
    let element = data.find(element => element[attribute] === id); 
    return element.price; 
}

export const Months = ({ year }) => {
    let date = new Date(); 
    let months = ['enero', 'febrero', 'marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']; 
    let count = 0; 
    if(Number('20'+year) === date.getFullYear()) {
        months = months.slice(date.getMonth(), 12); 
        count = date.getMonth()
    }
    
    return(
        <Fragment>
            {months.map((month) => {
                let val = count >= 9 ? count + 1 : `0${count + 1}`; 
                count++
                return <option key={month} value={val}>{month}</option>
            })}
        </Fragment> 
    )
}

export const Years = () => {
    let date = new Date(); 
    let yearIni = date.getFullYear(); 
    let years = []; 
    for(let i = yearIni; i <= yearIni + 10; i++) years.push(i); 
    return (
        <Fragment>
            {years.map((year) => {
                return(
                    <option key={year} value={String(year).slice(2,4)}>{year}</option>
                )
            })}
        </Fragment>
    )
}

export const getErrorCardOpenpay = {
    '1001': 'El nombre de titular es requerido',
    '2004': 'El dígito de verificación del número de tarjeta no es válido',
    '2005': 'La fecha de vencimiento ya pasó',
    '2006': 'Se requiere el código de seguridad CVV'
}