import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ImgComponent, InputComponent, ParagraphOne } from './../components/common/index.styled';
import { CartContext } from '../context/contextCart'; 
//images
import logo from './../assets/img/logo.svg';
import logoMobile from './../assets/img/icons/logo_mobile.png';
import iconCart from './../assets/img/icons/iconCart.png';
import iconCartBlue from './../assets/img/icons/cart_blue.png';
import CartFloat from './CartFloat'; 
import userICon from './../assets/img/icons/iconSession.png';

const MenuDesktop = ({ cart, total }) => {
    return(
        <div className="d-flex justify-content-between align-items-center flex-wrap w-100">
            <div className="col-md-2 col-lg-1">
                <Link to="/" >
                    <ImgComponent widthComponent="120px" className="logo" src={logo} />
                </Link>
            </div>
            <div className="col-md-5 col-lg-6 col-xl-8">
                <InputComponent className="search_input form-control" type="text" placeholder="Busca tus productos aquí" />
            </div>
            <div className="col-md-5 col-lg-4 col-xl-3 pr-0">
                <div className="d-flex">
                    <div className="login_icon d-flex align-items-center px-md-2 px-lg-3">
                        <p className="text-left pl-3">Ingresa a <span> Mi cuenta</span></p>
                    </div>
                    <div className="cart d-flex justify-content-around align-items-center position-relative">
                        <div className="cart_icon position-relative">
                            <ImgComponent widthComponent="30px" src={iconCart} className="img-fluif" />
                            <span className="cart_count_header">{cart.count}</span>
                        </div>
                        <p className="total_header">${parseFloat(total).toFixed(2)}<span id="change"> MXN</span></p>
                        <CartFloat cart={cart} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const MenuMobile = ({ cart, total }) => {
    return(
        <div id="header_mobile" className="d-flex justify-content-between align-items-center flex-wrap w-100">
            <div className="col-4">
                <Link to="/" className="logo_mobile_header" >
                    <ImgComponent widthComponent="170px" className="logo py-1 py-md-3 px-2" src={logoMobile} />
                </Link>
            </div>
            <div className="col-4">
                <div className="cart_mobile_float d-flex justify-content-between align-items-center">
                    <ImgComponent widthComponent="25px" className="mr-2" src={userICon} />
                    <div className="cart_icon position-relative mx-3">
                        <ImgComponent widthComponent="30px" src={iconCartBlue} className="img-fluif" />
                        <span className="cart_count_header">{cart.count}</span>
                    </div>
                    <CartFloat cart={cart} />
                </div>
            </div>
            <div className="col-12 mt-4">
                <InputComponent className="search_input form-control" type="text" placeholder="Busca tus productos aquí" />
            </div>
        </div>
    )
}

const Header = () => {
    const { cart, total } = useContext(CartContext);
    return(
        <div id="header">
            <div className="container-fluid">
                <div>
                    <div className="topHeader col-12">
                        <div className="col-md-11 ml-auto">
                            <div className="d-flex justify-content-between flex-wrap px-md-2">
                                <div className="icons d-flex ml-md-4 ml-lg-0 invisible">
                                    <Link to="/facturacion" className="text_icon">
                                        Facturación
                                    </Link>
                                    <Link to="/centro-ayuda" className="text_icon">
                                        Centro de ayuda
                                    </Link>
                                </div>
                                <div className="icons d-flex contact">
                                    <Link to="/sucursales" className="text_icon invisible">
                                        Ubicación de tiendas
                                    </Link>
                                    <ParagraphOne className="text_icon">Chat </ParagraphOne>
                                    <a href="tel:55-1328-0670" className="text_icon">Centro de ayuda: 55-1328-0670</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <MenuDesktop cart={cart} total={total} />
                    <MenuMobile cart={cart} total={total} />
                    <div className="bottomHeader">
                        <div className="d-flex flex-wrap alig-items-center py-2">
                            <Link id="menu" to="/departamentos">Servicios Financieros</Link>
                            <div className="d-flex justify-content-between align-items-center">
                                <Link to="/chedrauilandia">Chedrauilandia</Link>
                                <Link to="/outlet">Outlet</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
 
export default Header;