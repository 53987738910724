import React, { useState, Fragment } from 'react';
import { Div } from './common/index.styled'; 
import Loader from './common/Loader';
import { Months, Years } from './../helpers'; 

const FormCharge = ({card, updateCard,load,handleSetLoader, openpayProcessCharge, isIframe}) => {
    const [year, setYear] = useState(card.expiration_month); 
    const handleChange = e => {
        if(e.target.name === "card_number") {
            if(isNaN(Number(e.target.value.split('-').join('')))) return false;
        }else if(e.target.name === "expiration_year") {
            setYear(e.target.value);
            updateCard({ target: { value: '', name: 'expiration_month'}});
        }
        updateCard(e);
    }
    const handleSubmit = async e => {
        handleSetLoader(true);
        e.preventDefault(); 
        openpayProcessCharge(); 
    }

    const handleChangeCardNumber = e => {
        if(e.target.value.length === 19) return; 
        let val = [...e.target.value].filter(element => element !== '-'); 
        if(val.length %4 === 0 && val.length !== 0 && val.length <= 19 && e.target.value[e.target.value.length -1] !== '-') {
            e.target.value += '-'; 
        }
    }

    return ( 
        <Div id="checkout_form" className="w-100 mx-auto my-4">
            { load ? <Loader /> : null }
            <h5>Pago con tarjeta bancaria</h5>
            <Div className="col-12 col-md-11 mx-auto pl-0 pl-md-4">
                <Div mt="0" bgColor="#fff" p_xs="2rem" p="1.5rem" className="card content text-left">
                    <form onSubmit={e => handleSubmit(e)}>
                        {isIframe ?
                            <Fragment>
                                <p className="font-weight-bold px-3">Detalle de compra</p>
                                <div className="underline"></div>
                                <div className="ml-3 mb-4">
                                    <p>Descripción: <span>{sessionStorage.getItem('description')}</span></p>
                                    <p>Monto: <span>{Number(sessionStorage.getItem('amount')).toFixed(2)} MXN</span></p>
                                </div>
                            </Fragment>
                        : null}
                        
                        <p className="font-weight-bold px-3">Información de tarjeta</p>
                        <div className="underline"></div>
                        <div className="row w-100 mx-auto mt-3">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="card_number">Número de tarjeta*:</label>
                                    <input type="text" name="card_number" value={card.card_number} placeholder="Introduce 16 dígitos" maxLength="19" onChange={handleChange} onKeyPress={handleChangeCardNumber} className="form-control"  />
                                </div>
                                <div className="form-group mt-2">
                                    <label htmlFor="holder_name">Nombre completo del titular*:</label>
                                    <input type="text" name="holder_name" value={card.holder_name} placeholder="Nombre completo del titular" maxLength="40" onChange={handleChange} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="expiration_year">Año de vencimiento*:</label>
                                    <select name="expiration_year" value={card.expiration_year} className="form-control" onChange={handleChange}>
                                        <option value="">Selecciona el año</option>
                                        <Years />
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="expiration_month">Mes de vencimiento*:</label>
                                    <select name="expiration_month" value={card.expiration_month} className="form-control" onChange={handleChange}>
                                        <option className="dropdown-item" value="">Mes de vencimiento</option>
                                        <Months year={year} />
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="typeCard">Tipo de tarjeta*:</label>
                                    <select name="typeCard" className="form-control">
                                        <option value="debit">Tarjeta de crédito</option>
                                        <option value="credit">Tarjeta de debito</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="cvv2">CVV*:</label>
                                    <input type="text" name="cvv2" value={card.cvv2} onChange={handleChange} maxLength="3" placeholder="3 dígitos" className="form-control" />
                                </div>
                                <input type="submit" value="Pagar" className="btn secondary_button" />
                            </div>
                        </div>
                    </form>
                </Div>
            </Div>
        </Div>
     );
}
 
export default FormCharge;