import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query GetCustomer($idCustomer: String, $idCard: String) {
        customer(idCustomer: $idCustomer) @rest(path: "openpay/customers/{args.idCustomer}", method: "GET", type: "Customer") {
            response {
                success
                data {
                    id @export(as: "id")
                    name
                    cards(idCard: $idCard) @rest(path: "openpay/cards/{exportVariables.id}?idCard={args.idCard}", method: "GET", type: "[Customer]") {
                        response {
                            success
                            data {
                                id
                                type
                                brand
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_GIFT_CARDS = gql`
    query GetGiftCards($from: String, $to: String) {
        cards(from: $from, to: $to) @rest(path: "blackhawk/products/?from={args.from}&to={args.to}", method: "GET", type: "GiftCards") {
            success
            statusCode
            total
            response {
                entityId
                summary {
                    productName
                    productLineId @export(as: "id")
                    universalProductCode
                    currency
                }
                details {
                    productDescription,
                    defaultProductConfigurationId,
                    activationCharacteristics {
                        baseValueAmount,
                        maxValueAmount,
                        isVariableValue
                    }
                    fulfillmentCharacteristics {
                            id,
                            holdTimeSeconds,
                            printingType,
                            provisioningType,
                            fulfillmentMethod
                    }
                    productConfigurations {
                        configurationId
                        configurationName
                        configurationStatus
                        productImages
                    }
                    productCategory
                }
              
            }
        }
    }
`;
