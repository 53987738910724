import React, { Fragment, useContext } from 'react';

import { CartContext } from './../context/contextCart'; 
import { useHistory } from 'react-router-dom';

const OneProduct = ({product}) => (
    <Fragment>
        <div className="body">
            <h5>Código de activación de tu tarjeta</h5>
            <h2>{product.data.retrievalReferenceNumber}</h2>
            <p>Con este número podrás <span>usar</span> tus tarjetas en sus plataformas de redención.</p>
        </div>
    </Fragment>
)

const Products = ({ productsBought, images }) => {
    return(
        <Fragment>
            <div className="body">
                <h5>Estos son los códigos de activación de tus tarjetas</h5>
                <p>Con este número podrás <span>usar</span> tus tarjetas en sus plataformas de redención.</p>
                <div className="col-md-10 col-12 m-auto order_success_list">
                    {productsBought.map((product, index) => (
                        <div className="row w-100 mx-0 my-4" key={product.data.eGiftId}>
                            <div className="col-5 d-flex justify-content-left align-items-center pr-0">
                                <div className="image_success_content d-flex justify-content-center align-items-center">
                                    <img src={images[index].image} className="img-fluid" alt={product.data.eGiftId} />
                                </div>
                            </div>
                            <div className="col-7 d-flex justify-content-left align-items-center pl-0">
                                <div className="content text-left">
                                    <p>Numero de activación:</p>
                                    <p>{product.data.retrievalReferenceNumber}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

const OrderSuccess = () => {
    const { productsBought } = useContext(CartContext);
    let history = useHistory(); 
    const handleChange = () => {
        localStorage.removeItem('productsBought');
        history.push('/');
    }
    return ( 
        <div className="order_success my-md-5 mb-5 mt-3">
            <div className="col-md-7 col-lg-6 col-xl-4 col-11 m-auto">
                <div className="order_succes_item">
                    <div className="header d-flex justify-content-center align-items-center">
                        <h4>Pedido exitoso</h4>
                        <div className="pl-5"></div>
                    </div>
                    {productsBought.products.length === 1 ? 
                        <OneProduct product={productsBought.products[0]} />
                        : <Products productsBought={productsBought.products} images={productsBought.images} />
                    }
                    <div className="footer">
                        <p>Este número ha sido enviado a tu correo, para cualquier aclaración
                            contáctanos a través de nuestro call center (00) 0000 - 0000</p>
                        <button className="btn secondary_button" onClick={() => handleChange()}>Regresar al menú de servicios</button>    
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default OrderSuccess;