import { gql } from "@apollo/client";

export const CREATE_CHARGE = gql`
    mutation createCharge($chargeInput: chargeInput) {
        createCharge: createCharge(body: $chargeInput)
        @rest(
        type: "Charge"
        path: "openpay/charges"
        method: "POST"
        bodyKey: "body"
        ) {
            response {
                statusCode
                success
                data {
                    id
                    authorization
                    transactionId
                    amount
                    card {
                        card_number
                    }
                    refund {
                            authorization
                            amount
                            transaction_type
                            status
                            id
                            description
                            currency
                    }
                }
                products {
                    data {
                        accountId,
                        retrievalReferenceNumber,
                        productConfigurationId,
                        status,
                        suspended,
                        riskScore
                        hasBeenViewed
                        eGiftId,
                        upc,
                        eGiftUrl
                    }
                }
            }
        }
    }
`