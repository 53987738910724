import React, { createContext, useState, useEffect, useCallback } from 'react';

export const CartContext = createContext(); 
const initialState = {
    products: [],
    count: 0
}

const CartProvider = props => {
    const [total, setTotal] = useState(0);
    const [isIframe, setIsIframe] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('invitedEmail') || '');
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cartProducts')) || initialState);
    const [productsBought,setProductsBought] = useState(JSON.parse(localStorage.getItem('productsBought')) || { images: [], products: []});
    const handleChangeTotal = useCallback(cart => {
            let total = 0; 
            for(const product of cart.products) total += product.price * product.count;
            setTotal(total);
        },[setTotal])

    useEffect(() => {
        localStorage.setItem('cartProducts', JSON.stringify(cart))
        if(cart.products.length !== 0 || localStorage.getItem('cartProducts') === null) {
            localStorage.setItem('cartProducts', JSON.stringify(initialState));
        }
        localStorage.setItem('cartProducts', JSON.stringify(cart))
        handleChangeTotal(cart); 
    }, [cart, handleChangeTotal])

    useEffect(() => {
        localStorage.setItem('invitedEmail', email)
    }, [email]); 

    useEffect(() => {
        if(productsBought.products.length !== 0) {
            localStorage.setItem('productsBought', JSON.stringify(productsBought));
        }
    }, [productsBought])

    return(
        <CartContext.Provider value={{ cart, setCart, total,setTotal, email, setEmail,isIframe, setIsIframe, productsBought,setProductsBought }}>
            {props.children}
        </CartContext.Provider>
    )
}

export default CartProvider; 