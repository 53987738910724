import React from 'react';
import { Div } from './common/index.styled'; 

const Features = ({ features }) => {
    return ( 
        <Div id="features" className="row w-100 m-md-0 mx-0" bgColor="#fff" mt="0" mt_xs="2rem">    
            { features.map((feature, index) => (
                <div key={index} className={`feature ${feature.col}`}>
                    <div className="content">
                        <h4>{feature.title}</h4>
                        <img src={feature.img} alt={feature.title} />
                    </div>
                </div>
            ))}
        </Div>
    );
}
 
export default Features;