import React, { useEffect } from 'react';
import loader from './../../assets/img/icons/load.gif'

const Loader = ({ load, handlerLoader }) => {
    useEffect(() => {
        if(load) {
            setTimeout(function() {
                handlerLoader(); 
            }, 3500)
        }
    }, [load, handlerLoader])
    
    return ( 
        <div className="loader">
            <div>
                <img src={loader} alt="loader" />
            </div>
        </div>
    );
}
 
export default Loader;