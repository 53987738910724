import React from "react";
import Slider from "react-slick";

const Carousel = ({dots, centerMode, slidesToShow, slidesToScroll, slidesToShowMd, children}) => {
    const settings = {
        arrows: true,
        autoplay: true,
        dots: dots || false,
        centerPadding: '100px',
        centerMode: centerMode || false,
        infinite: true,
        speed: 1000,
        slidesToShow: slidesToShow || 1,
        slidesToScroll: slidesToScroll || 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              centerMode: centerMode || false,
              centerPadding: '40px',
              slidesToShow: slidesToShowMd || 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              dots: true,
              arrows: true,
              centerMode: false,
              centerPadding: '45px',
              slidesToShow: 1
            }
          }
        ]
    }
    return ( 
        <Slider {...settings}>
            {children}
        </Slider>
     );
}
 
export default Carousel;