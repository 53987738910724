import React, { useEffect, Fragment, useState } from 'react';
import { useQuery } from "@apollo/client";
import { GET_GIFT_CARDS } from './../queries/queries';
import GiftCard from './GiftCard';
import load from './../assets/img/icons/load.gif'
const GiftCards = React.memo(({ gifts, handleChangeGifts, handleChangeData, handleChangeTotal, from, to }) => {
    const {loading, data, error} = useQuery(GET_GIFT_CARDS, { variables: { from, to}});
    const [products, setProducts] = useState([]);
    const [categories, setcategories] = useState([]);
    useEffect(() => {
        if(data !== undefined) {
            handleChangeTotal(data.cards.total);
            handleChangeGifts(data.cards.response);
            setProducts(data.cards.response);
            handleChangeData(data);
        }
    },[handleChangeData,handleChangeGifts,data,handleChangeTotal]);
    useEffect(() => {
        setProducts(gifts);
    }, [gifts])

    useEffect(() => {
        let typeOne = {
            title: 'Categoría 1',
            data: []
        };
        let typeTwo = {
            title: 'Categoría 2',
            data: []
        };
        let others = {
            title: 'Categoría 3',
            data: []
        };
        products.map(card => {
                let gift = card
                if(card.summary.productName.indexOf('INVALID') > -1) {
                    typeOne.data.push(gift); 
                } else if(card.summary.productName.indexOf('200') > -1) {
                    typeTwo.data.push(gift);
                } else {
                    others.data.push(gift);
                }
                return [typeOne,typeTwo,others]
        })
        setcategories([typeOne,typeTwo,others])
    }, [products])

    if(loading) {
        return (
            <h2 className="element_empty text-center w-100">
                <img src={load} alt="loader" />
            </h2>
        )
    }
    if(error) {
        return <h2 className="element_empty text-center w-100">Revisa tu conexión...</h2>
    }
    
    return (
        <div className="w-100 m-0 gift_cards_content">
            <Fragment>
                {categories.map(category => (
                    <Fragment key={category.title}>
                        {category.data.length === 0 ? null
                            : 
                            <Fragment>
                                <div className="category w-100">
                                    <h4>{category.title}</h4>
                                </div>
                                <div className="row w-100 m-0">
                                    {category.data.map(card => (
                                        <GiftCard key={card.details.fulfillmentCharacteristics[0].id} card={card} />
                                    ))}
                                </div>
                            </Fragment>
                        }
                    </Fragment>
                ))}
            </Fragment>
        </div>
    )
})
 
export default GiftCards;