import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';  

export const LinkComponent = ({className,to,children}) => (
    <Link to={to} className={className}>
        {children}
    </Link>
);

export const imageComponent = ({className, src, alt}) => (
    <img className={className} alt={alt} src={src} />
)

export const card = ({ image, title, description, link }) => (
    <Card>

    </Card>
);

const Card = styled.div`
    border-radius: 10px; 
    background-color: #fff; 
    padding: 1rem;
`;


