import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'; 
import { ApolloProvider } from '@apollo/client'; 
//css
import './assets/css/master.css'
//Components
import client from './config/apollo'; 
import Header from './components/Header'; 
import Footer from './components/Footer';
import Loader from './components/common/Loader';
import { Div } from './components/common/index.styled';
import { Home } from './chedraui/Home';
import Products from './chedraui/Products';
import Cart from './chedraui/Cart';
import CartIframe from './chedraui/CartIframe';
import CartInitial from './chedraui/CartInitial';
import CartProvider from './context/contextCart'; 
import OrderSuccess from './chedraui/OrderSuccess';
import ScrollToTop from './components/ScrollTop'; 

const App = () => {
  const [load, setLoad] = useState(true)
  const handlerLoader = () => setLoad(false)
  useEffect(() => {
    if(load) {
      window.addEventListener('load', handlerLoader)
    }
  }, [load]); 
  return(
    <CartProvider >
      <ApolloProvider client={client}>
        <Router>
          <Div id="chedruiWrapper" className="m-0">
            {load ? <Loader load={load} handlerLoader={handlerLoader}/> : null}
            <Switch>
              <Route exact path="/payment-iframe" component={CartIframe} />
              <Router>
                <ScrollToTop />
                <Header />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/gift_cards" component={Products} />
                  <Route exact path="/cart_initial" component={CartInitial} />
                  <Route exact path="/cart" component={Cart} />
                  <Route exact path="/cart/order_success" component={OrderSuccess} />
                  <Route path='/404' component={Home} />
					        <Redirect from='*' to='/404' />		
                </Switch>
                <Footer />
              </Router>
            </Switch>
          </Div>
        </Router>
      </ApolloProvider>
    </CartProvider>
  )
}
  
 
export default App;