import React, { useContext,useState,useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Div } from './../components/common/index.styled';
import CartItem from './CartItem';
import Invited from './Invited';
import { CartContext } from './../context/contextCart'; 
import PaymentMethods from '../components/PaymentMethods';
import Checkout from '../components/Checkout';

const initialState = {
    products: [],
    count: 0
}
let titles = ['Resumen de carrito','','¿A qué correo enviaremos tu orden?','Selecciona tu método de pago'];

const StepOne = ({ products, cart, setCart, setEmail,stepActive, total, handleChangeStep }) => (
    <Div mt="0px" mt_xs="0px" className="cart_container">
        <div className="card_steps_buttons d-flex justify-content-center align-items-center">
            {[1,2,3].map(element => (
                <div key={`step-${element}`} className={element === stepActive + 1 ? 'active': null}>
                    <p>{element}</p>
                </div>
            ))}
        </div>
        { cart.products.length === 0 ? 
            <div className="mt-5">
                <h4 className="text-center">No hay productos en tu carrito</h4>
            </div>    
            : 
            <div className="mb-5 mb-md-0">
                <Div className="col-md-11 col-lg-8 m-auto cards-item">
                    {products.map(product => (
                        <CartItem key={product.productId} cart={cart} product={product} setCart={setCart}/>
                    ))}
                </Div>
                <div className="ol-md-9 col-lg-8 m-auto">
                    <div className="footer_cart position-relative">
                        <p className="clear_cart" onClick={() => { setCart(initialState); setEmail('')}}>Vaciar carrito</p>
                        <div className="cart_info col-md-4 ml-auto px-3">
                            <div className="subtotal">
                                <p className="d-flex justify-content-between">Subtotal <span>${total.toFixed(2)}</span></p>
                            </div>
                            <div>
                                <button className="btn secondary_button" onClick={() => handleChangeStep(1,'')}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </Div>
)

const StepTwo = ({ email, stepActive, handleChangeStep }) => {
    return(
        <Div mt="0px" mt_xs="0px" className="cart_container">
            <div className="card_steps_buttons d-flex justify-content-center align-items-center">
                {[1,2,3].map(element => (
                    <div key={`step-${element}`} className={element === stepActive ? 'active': null }>
                        {Number(element) < Number(stepActive) ? 
                            <span className="cart_step_check"></span>
                        :
                            <p>{element}</p>
                        }
                    </div>
                ))}
            </div>
            <Div className="col-10 col-md-9 col-lg-8 m-auto email_cart_info">
                <p className="w-100 text-right my-3 d-flex font-weight-bold justify-content-end align-items-center" onClick={() => handleChangeStep(1,'Agrega otro correo', 'El código de activación será enviado a los correos que agregues')}>
                    Agregar otro correo <span className="mx-3 d-flex align-items-center justify-content-center">+</span>
                </p>
                <div className="w-100 card_content">
                    <Div className="m-0 d-flex rounded_top justify-content-between align-items-center" p="1rem 1.5rem" p_xs=".75rem 1rem" bgColor="#f47d00">
                        <p className="text_icon mail_icon">Nombre del correo</p>
                        <div className="edit_icon" onClick={() => handleChangeStep(1,'Editar correo','Verifica que los datos sean correctos')}></div>
                    </Div>
                    {email.split(',').map(input => (
                        <Div className="mt-0 cart_email_content d-flex align-items-center" key={input} bgColor="#fff">
                            <p className="email text-left">{input}</p>
                            <div className="check_success ml-auto"></div>
                        </Div>
                    ))}
                    
                </div>
                <div className="cart_info col-md-4 ml-auto pl-3 mt-4 pr-md-0 mb-5 mb-md-0">
                    <div>
                        <button className="btn secondary_button" onClick={() => handleChangeStep(3,'Selecciona tu método de pago')}>Continuar</button>
                    </div>
                </div>
            </Div>
        </Div>
    )   
}

const StepThree = ({ stepActive, email, setEmail,isIframe, total, cart, setCart, setProductsBought, urlRedirect}) => {
    const [stepCard, setStepCard] = useState(1); 
    const handleChangeStep = step => {
        setStepCard(step)
    }

    let component = stepCard === 1 ? <PaymentMethods handleChangeStep={handleChangeStep} /> :
    <Checkout cart={cart} setCart={setCart} urlRedirect={urlRedirect} isIframe={isIframe} setProductsBought={setProductsBought} setEmail={setEmail} total={total} email={email} />

    return(
        <Div mt="0px" mt_xs="0px" className="cart_container">
            <div className="card_steps_buttons d-flex justify-content-center align-items-center">
                {[1,2,3].map(element => (
                    <div key={`step-${element}`} className={element === stepActive ? 'active': null}>
                        {Number(element) < Number(stepActive) ? 
                            <span className="cart_step_check"></span>
                        :
                            <p>{element}</p>
                        }
                    </div>
                ))}
            </div>
            <Div className="col-10 col-md-9 col-lg-8 m-auto email_cart_info">
                {component}
            </Div>
        </Div>
    )   
}

const Cart = ({ urlRedirect }) => {
    let history = useHistory();
    let location = useLocation(); 
    const { cart, setCart, total,email,setEmail, isIframe,setProductsBought } = useContext(CartContext);
    const [stepActive, setStepActive] = useState(email !== "" ? 2 : 0);
    const { products } = cart;
    let [titleEmail,setTitleEmail] = useState('');
    const [title, setTitle] = useState('')
    const handleChangeStep = useCallback((step,title, titleStep2='') => {
            setStepActive(step);
            setTitle(title);
            if(step === 1) {
                setTitleEmail(titleStep2);
            }
            history.push(`/cart?step=${step+1}`)
        },
    [setStepActive,history]);
   
    let step = '';

    switch(stepActive) {
        case 0: 
            step = <StepOne cart={cart} setCart={setCart} setEmail={setEmail} stepActive={stepActive} total={total} products={products} 
                    handleChangeStep={handleChangeStep}/>
        break; 
        case 1: 
            step = <Invited handleChangeStep={handleChangeStep} title={titleEmail} email={email} setEmail={setEmail} />
        break; 
        case 2: 
            step = <StepTwo email={email} stepActive={stepActive} handleChangeStep={handleChangeStep} />
        break; 
        case 3: 
            step = <StepThree email={email} urlRedirect={urlRedirect} isIframe={isIframe} setProductsBought={setProductsBought} setCart={setCart} setEmail={setEmail} total={total} cart={cart} stepActive={stepActive} />
        break;
        default: 
        break; 
    }

    const handleBack = useCallback(step => {
        if(step === 0 || step < 0) {
            step = 0;
            setTitle(titles[0])
        } else if(step === 1) {
            setTitle(titles[1])
        } else {
            setTitle(titles[3])
        }
        history.push(`/cart?step=${step+1}`)
        setStepActive(step)
    }, [setTitle, setStepActive,history]);

    useEffect(() => {
        let { search } = location;
        const stepQuery = window.location.pathname.indexOf('payment') > -1 ? '4' : search.split("step=")[1];
        setStepActive(stepQuery-1);
        if(stepQuery-1 !== 1) {
            setTitle(titles[stepQuery-1]);
        }
    }, [setStepActive, location])
    return ( 
        <Div mt="0" mt_xs="0" bgColor="#fff" p="3rem 0" id="cart">
            <div className="row w-100 m-0">
                <div className="col-md-3 d-flex justify-content-md-center justify-content-left">
                    {isIframe ? null 
                    : 
                    stepActive === 0 ? <Link to="/cart_initial" className="link_back icon_before ml-3 ml-md-0 mb-3 mb-md-0">Regresar</Link>
                        :
                        <p className="link_back icon_before ml-3 ml-md-0 mb-3 mb-md-0" onClick={() => handleBack(stepActive -1)}> Regresar</p>
                    }
                    
                </div>
                <h4 className="col-md-6">{title}</h4>
            </div>
            {step}
        </Div>
    );
}
 
export default Cart;