import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import left from './../assets/img/icons/left.png'; 
import right from './../assets/img/icons/right.png'; 

const Paginate = ({ total, limit, handleChangePaginate }) => {
    let location = useLocation();
    let { search } = location;
    let page = search.split("?page=")[1] || 1;
    const [pageActual, setPageActual] = useState(Number(page));
    const pages = []; 
    const handleChange = (page) => {
        if(page >=1 && page <= pages.length) {
            setPageActual(page)
        }
    };
    const changePage = useCallback(pageActual => {
        handleChangePaginate(pageActual)
    },[handleChangePaginate]);
    
    useEffect(() => {
        changePage(pageActual)
    }, [pageActual]);

    for(let i = 1; i <= parseInt(total / limit) + 1; i++) pages.push(i);
    return ( 
        <div className="paginate d-flex justify-content-center align-items-center w-auto mx-auto my-4">
            
            <div className={`before page d-flex justify-content-center align-items-center h-100 ${pageActual !== 1 ? null : 'disabled' }`} onClick={() => handleChange(pageActual -1)}>
                <img src={left}  alt="before" />
            </div>

            {pages.map(page => (
                <div key={page} className={ Number(page) === pageActual ? 'page active' : 'page'}  onClick={() => handleChange(page)}>
                    <p>{page}</p>
                </div>
            ))}
            <div className={`after page d-flex justify-content-center align-items-center h-100 ${pageActual !== pages.length ? null : 'disabled' }`} onClick={() => handleChange(pageActual + 1)}>
                <img src={right} alt="after" />
            </div>
        </div> 
    );
}
 
export default Paginate;