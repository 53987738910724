import React, { useState } from 'react';
import { Div, InputComponent } from '../components/common/index.styled';

const Invited = ({ handleChangeStep, email, setEmail, title }) => {
    const emailsSend = [];
    const [emails,setEmails] = useState(email === '' ? [{ email: '', confirmEmail: '' }] : email.split(',').map(input => { return { email: input, confirmEmail: input } }) ); 
    const [error, setError] = useState({ error: false, message: '', index: 0 })
    const addEmail = () => setEmails(emails.concat([{ email: '', confirmEmail: ''}]))
    const handleChange = id => e => {
        let newEmails = emails.map((email,index) => {
            if(id !== index) return email; 
            return {...email, [e.target.name]: e.target.value }
        })
        setEmails(newEmails)
    }
    const deleteEmail = id => setEmails(emails.filter((email, index) => index !== id));
    const handleSubmit = e => {
        e.preventDefault();
        console.log(emails)
        for(let i = 0; i < emails.length; i++) {
            emailsSend.push(emails[i].email); 
            if(emails[i].email !== emails[i].confirmEmail) {
                setError({
                    error: true, 
                    message: `Correo ${i+1} no coincide`,
                    index: i
                })
                return; 
            }
            setError({
                error: false, message: ``, index: i
            })
        }
        setEmail(emailsSend.join(',')); 
        handleChangeStep(2,'¿A qué correo enviaremos tu orden?')
    }

    let errorMessage = error.error ? <p className="alert alert-danger" role="alert">{error.message}</p> : null; 
    return ( 
        <Div className="d-flex justify-content-center" >
            <div className="col-10 col-md-4 m-auto">
                { title === '' ? <h4>Entra como invitado</h4> : <h5>{title}</h5> }
                <form onSubmit={handleSubmit} className="mb-5 mb-md-0">
                    {emails.map((input, index) => (
                        <div className={index > 0 ? 'line_separator' : null} key={index}>
                            <div className="form-group text-left">
                                <label>Dirección de correo electrónico {index !== 0 ? index +1 : ''}</label>
                                <div className="position-relative">
                                    <InputComponent type="email" className="form-control" name='email' value={input.email} onChange={handleChange(index)} required />
                                    { emails.length > 1 ? <div className="delete_product_cart_initial" onClick={() => deleteEmail(index)}></div> : null }
                                </div>
                            </div>
                            <div className="form-group text-left">
                                <label>Confirmar correo electrónico</label>
                                <InputComponent type="email" className="form-control" name="confirmEmail" value={input.confirmEmail} onChange={handleChange(index)} required/>
                            </div>
                        </div>
                    ))}
                    {errorMessage}
                    {title !== '' && title.indexOf('Verifica') === -1 ?
                        <p className="w-100 text-right my-3 d-flex font-weight-bold justify-content-end align-items-center" onClick={addEmail}>Agregar otro correo <span className="mx-3 d-flex align-items-center justify-content-center">+</span></p>
                        : null 
                    }
                    <button type="submit" className="btn secondary_button mt-3">Continuar como invitado</button>
                </form>
            </div>
        </Div>
     );
}
 
export default Invited;