import React from 'react';
import { Div, ImgComponent } from './common/index.styled'; 
import chatIcon from '../assets/img/iconPRogramaAsistenciasW.png'

const ChatFloat = () => {
    return ( 
        <Div id="chat" className="text-left">
            <ImgComponent src={chatIcon} alt="chat_icon" className="img-fluid mb-0 mb-md-4" widthComponent="30%" />
            <p>¡Necesitas ayuda!</p>
            <p>Escribe tu pregunta aquí y te responderemos a la brevedad</p>
        </Div>
     );
}
 
export default ChatFloat;