import React,{ useState,useCallback } from 'react'
import ButtonIncrement from '../components/common/ButtonIncrement';

const CartItem = ({ product,cart,setCart }) => {
    const [count, setCount] = useState(product.count);
    let products = cart.products;

    const handleChangeCount = useCallback(val => {
        setCount(val)
        for(const element of products) {
            if(element.productId === product.productId) element.count = val; 
        }
        setCart({
            ...cart,
            products
        })
    },[setCount,setCart,cart, products, product])

    const handleDelete = (product) => {
        let newData = products.filter(element => element.productId !== product.productId); 
        setCart({
            ...cart,
            products: newData,
            count: newData.length
        })
    }

    return ( 
        <div className="row w-100 m-0">
            <div className="col-9 col-md-8 d-flex align-items-center">
                <div className="cart_image">
                    <img alt={product.productName} src={product.productImage} />
                </div>
                <div className="col-8 col-lg-5 text-left mt-4">
                    <p className="cart_product_name">{product.productName}</p>
                    <ButtonIncrement handleChangeCount={handleChangeCount} actual={count} />
                </div>
            </div>
            <div className="col-3 col-md-4 d-flex align-items-center">
                <div className="cart_price text-right w-100 position-relative d-flex w-100 justify-content-end align-items-center">
                    <p className="price">${(product.price).toFixed(2)}</p>
                    <div className="delete_product_cart_initial ml-2" onClick={() => handleDelete(product)}></div>
                </div>
            </div>
        </div>
     );
}
 
export default CartItem;