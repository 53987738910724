import React, { useState, useEffect, useCallback} from 'react';

const ButtonIncrement = ({ actual, handleChangeCount }) => {
    const [buttonDisable, setButtonDisable] = useState(false);
    const [count, setcount] = useState(1);
    useEffect(() => {
        setcount(actual)
    },[setcount, actual]);

    useEffect(() => {
        count === 1 ? setButtonDisable(true) : setButtonDisable(false);
    }, [count, setButtonDisable]);

    const handleChange = useCallback(val => {
        setcount(val);
        handleChangeCount(val);
    },[setcount,handleChangeCount]);

    return ( 
        <div className="d-flex justify-content-between align-items-center counter">
            <button className="btn" onClick={() => handleChange(count - 1)} disabled={buttonDisable ? 'disabled' : false} >-</button>
            <p>{count > 1 ? `${count} piezas` : `${count} pieza`}</p>
            <button className="btn" onClick={() => handleChange(count + 1)}>+</button>
        </div>
    );
}
 
export default ButtonIncrement;