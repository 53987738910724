import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom'; 
import { useWindowSize } from '../helpers/hooks';
import { Div, InputComponent } from './../components/common/index.styled';
import GiftCards from './GiftCards';
import Paginate from '../components/Paginate';

const items = [
    {title: "Paga de forma fácil y segura", text: "Aceptamos cualquier tarjeta de crédito o paga desde tu cuenta Chedraui"},
    {title: "Compra desde casa todos los días  a cualquier hora", text: "Todas tus tarjetas son en formato digital"},
    {title: "Recíbela al instante", text: "Recibirás por correo electrónico o  mensaje de texto el código de tu tarjeta"},
    {title: "Código digital", text: "Puedes canjear tu código en la plataforma de tu preferencia una vez realizada tu compra"},
    {title: "Formato de regalo", text: "Envía a quien quieras tu tarjeta como regalo"}
]

const Products = React.memo(() => {
    let history = useHistory(); 
    const size = useWindowSize();
    const [keyword, setkeyword] = useState(""); 
    const [gifts, setGifts] = useState([]); 
    const [data, setData] = useState([]);
    const [limit, setLimit] = useState(size.width < 1023 ? 6 : 9);
    const [total, setTotal] = useState(0);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(limit); 
    const handleChangeData = useCallback(data => {
        setData(data); 
    }, [setData]); 

    const handleChangeGifts = useCallback(data => {
        setGifts(data); 
        //console.log("Actualiza data", data)
    }, [setGifts]); 
    const handleChangeTotal = useCallback(result => {
        setTotal(result)
    }, [setTotal]);

    useEffect(() => {
        //console.log(gifts)
        if(gifts === []) {
            handleChangeGifts(gifts)
        }
    },[handleChangeGifts,gifts]); 

    useEffect(() => {
        if(size.width < 1023) {
            setLimit(6);
            setTo(from + 6)
        } else {
            setLimit(9);
            setTo(from + 9);
        }
    },[from,size,setLimit])

    const handleChangeKeyword = useCallback( e => {
        if(e.target.value.length > 3) setkeyword(e.target.value)
    },[setkeyword]); 

    const filterData = useCallback( value => {
        let newData = gifts.filter(gift => gift.summary.productName.toLowerCase().indexOf(value) > -1);
        //console.log(newData)
        setGifts(newData)
    },[gifts, setGifts])

    const handleChangeFilter = e => {
        //console.log("Filtrar", e.target.value)
        if(e.target.value !== "") {
            filterData(e.target.value); 
        } else {
            setGifts(data.cards.response)
        }
    }

    const handleChangePaginate = (page) => {
        //console.log("Change paginate", page); 
        history.push(`/gift_cards?page=${page}`);
        setFrom(limit*(page-1)); 
        setTo(page*limit);
    }
    
    return ( 
        <Div id="gift-cards" mt_xs="1rem"  className="col-lg-12 col-xl-10 mx-auto text-left">
            <h2 className="text-left font-weight-bold mx-2 px-3 pt-3">Tarjetas digitales </h2>
            <p className="mx-2 px-3">Las tarjetas digitales sirven para comprar o recargar saldo en tiendas online y en plataformas de entretenimiento </p>
            <Div className="d-flex flex-wrap mb-4" mt="20px">
                <Div className="col-md-7 col-lg-8 d-flex justify-content-between flex-wrap" mt="0" mt_xs="0">
                    <form onSubmit={ e => e.preventDefault()} className="col-12">
                        <div className="row w-100 m-0">
                            <div className="col-8 col-md-9">
                                <div className="form-group">
                                    <InputComponent type="text" name="gift" className="form-control search_input" onChange={handleChangeKeyword} placeholder="Busca tu tarjeta aquí" /> 
                                </div>
                            </div>
                            <div className="col-4 col-md-3">
                                <div className="form-group">
                                    <select name="filter" onChange={handleChangeFilter} className="form-control">
                                        <option value="">Filtrar por marca</option>
                                        <option value="200">Filtrar por 200</option> 
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <GiftCards gifts={gifts} handleChangeTotal={handleChangeTotal} from={from} to={to} keyword={keyword} handleChangeGifts={handleChangeGifts} handleChangeData={handleChangeData} />
                    <Paginate total={total} limit={limit} handleChangePaginate={handleChangePaginate}  />
                </Div>
                <Div className="text-left col-md-5 col-lg-4 m-0 px-4">
                    <div className="content card p-4">
                        {items.map((item, index) => (
                            <div key={index} className="item_list">
                                <h6 className="mb-0 mt-3">{item.title}</h6>
                                <p>{item.text}</p>
                            </div>
                        ))}
                    </div>
                </Div>
            </Div>
        </Div>
     );
})
 
export default Products;