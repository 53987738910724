//libraries
import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client'; 
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
//components
import FormCharge from './FormCharge';
import { OpenPay } from './../helpers'; 
import { CREATE_CHARGE } from './../mutations/mutations';
import { randomWord, getErrorCardOpenpay } from './../helpers/index';

let device_session_id = OpenPay.deviceData.setup("formId"); 
const initialState = {
    products: [],
    count: 0
}
const Checkout = ({ email, setEmail, isIframe, cart, setCart, total, setProductsBought, urlRedirect }) => {
    let history = useHistory();
    const [load, setLoad] = useState(false); 
    const handleSetLoader = (val) => {
        setLoad(val)
    }
    //State
    const customer = {
        email,
        requires_account: false
    }
    let temporal = [];
    const imagesProducts = [];
    if(!isIframe) {
        cart.products.map((product, index) => {
            for(let i = 0; i < product.count; i++) {
                let temp = {
                    id: product.productId,
                    productConfigurationId: product.productConfigurationId,
                    provider: "Blackhawk",
                    type: "gitf_card", 
                    amount: product.price,
                    count: product.count
                };
                let img = {
                    id: product.productId,
                    image: product.productImage
                };
                imagesProducts.push(img); 
                temporal.push(temp)
            }
            return temporal;
        })
    } else {
        cart.products.map((product) => {
            console.log(product);
            for(let i = 0; i < product.count; i++) {
                let temp = { ...product, amount: product.price }
                temporal.push(temp)
            }
            return temporal;
        })
    }
    
    const products = temporal; 
    const [card, setCard] = useState({ 
        card_number: '4111-1111-1111-1111', 
        holder_name: 'Carlos Jesus Mata Franco', 
        expiration_year: '21', 
        expiration_month: '03', 
        cvv2: '050'
    });
    let form = {
        method: 'card',
        amount: total,
        currency: 'MXN',
        description: 'Cargo inicial a mi cuenta',
        order_id: `orderid-${randomWord()}-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`,
        device_session_id
    }; 
    if(isIframe) {
        form = { ...form, orderId: sessionStorage.getItem('orderId') }
    }
    const [createCharge] = useMutation(CREATE_CHARGE, {
        onCompleted: ({createCharge}) => {
            handleSetLoader(false)
            console.log(createCharge);
            const { card_number } = createCharge.response.data.card;
            const { refund } = createCharge.response.data;
            if(!createCharge.response.success) {
                return Swal.fire({ icon: 'error',title: `Se realizó Reembolso $${refund.amount.toFixed(2)} a la tarjeta con terminación ${[...card_number].slice(12,16).join('')}`,
                text: `${refund.description}` })
            }
            setEmail('');
            if(!isIframe) {
                setProductsBought({ images: imagesProducts, products: createCharge.response.products });
                history.push(`/cart/order_success`);
            } else {
                const { transactionId } = createCharge.response.data; 
                let urls = urlRedirect;
                setCart(initialState);
                window.top.location.href =`${urls.urlSuccess}?transactionId=${transactionId}`;
            }
            setCart(initialState);
        },
        onError: (error) => {
            if(isIframe) {
                let urls = urlRedirect;
                //window.top.location.href = urls.urlError;
            }
            handleSetLoader(false);
            return Swal.fire('Error de conexión',
            'Recarga tu página y vuelve a intentar',
            'question'
            )
        }
    })
    //Functions call api and setState
    const charge = async cardToken => {
        createCharge({variables: {chargeInput: { name: card.holder_name, ...customer, ...cardToken,...form, products }}})
    }
    const createNewCard = () => {
        return new Promise(resolve => {
            OpenPay.token.create({...card, card_number: card.card_number.split('-').join('') },(cardToken) => resolve({id_card_openpay: cardToken.data.id, ...cardToken.data.card }), (error) => {
                handleSetLoader(false); 
                console.log(error)
                Swal.fire({ icon: 'error',title: getErrorCardOpenpay[error.data.error_code] })
            });
        })
    }
    const updateCard = useCallback((e) => {
        setCard({
            ...card,
            [e.target.name]: e.target.value
        })
    }, [card,setCard]);

    const openpayProcessCharge = async () => charge(await createNewCard());

    return <FormCharge handleSetLoader={handleSetLoader} load={load} isIframe={isIframe} openpayProcessCharge={openpayProcessCharge} updateCard={updateCard} card={card} />
}
 
export default Checkout;