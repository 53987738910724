import React from 'react';

const PaymentMethods = ({ handleChangeStep }) => {
    const handleChange = () => {
        handleChangeStep(2);
    }
    return ( 
        <div className="col-md-5 col-xl-3 mx-auto mb-4 p-0">
            <h5>Pagos en línea</h5>
            <div className="payment_method mt-4 text-left">
                <h4>Pago con tarjeta bancaria</h4> 
                <p>Realiza el pago con tarjeta de crédito, 
                débito o vales electrónicos</p>

                <p className="text_link mt-4 d-flex align-items-center" onClick={handleChange}>Seleccionar Tarjeta <span className="icon_next ml-2"></span></p>   
            </div>
        </div>
     );
}
 
export default PaymentMethods;