import React, {useContext, Fragment} from 'react';
import CardItemDetail from './CardItemDetail';
import { CartContext } from './../context/contextCart'; 
import { Div, ButtonLink } from '../components/common/index.styled';
import { deleteElement } from '../helpers/index';

const CartInitial = () => {
    const { cart, setCart } = useContext(CartContext);
    const handleDelete = product => {
        let products = deleteElement(cart.products, product.productId, 'productId'); 
        console.log(products)
        setCart({
            products,
            count: products.length
        })
    }
    return ( 
        <Div className="cart_step_initial col-md-10 m-auto">
            {cart.products.length === 0 ? 
                <div className="element_empty">
                    <div>
                        <h2>Tu carrito está vació</h2>
                        <ButtonLink className="secondary_button btn" to="/" >Volver al incio</ButtonLink>
                    </div>
                </div>
            :
                <Fragment>
                    {cart.products.map(product => (
                        <CardItemDetail cart={cart} setCart={setCart} key={product.productId} handleDelete={handleDelete}  product={product} />
                    ))}
                    <div className="col-md-3 ml-auto mb-5 pr-md-0 px-4">
                        <ButtonLink className="btn secondary_button" to="/cart?step=1" >Continuar</ButtonLink>
                    </div>
                </Fragment>
                
            }
            
        </Div>
    );
}
 
export default CartInitial;