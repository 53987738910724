import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cart from './Cart'
import { CartContext } from './../context/contextCart';
require('./../config/config.js'); 

const CartIframe = () => {
    let location = useLocation(); 
    const [products, setProducts] = useState([]); 
    const [params, setParams] = useState('')
    const [urlSuccess, setUrlSuccess] = useState('');
    const [error, setError] = useState({
        status: false, 
        message: ''
    })
    const [urlError, setUrlError] = useState('');
    const [publicKey, setPublicKey] = useState(''); 
    const { setIsIframe,setEmail, cart,setCart } = useContext(CartContext);

    useEffect(() => {
        setIsIframe(true) 
    })
    useEffect(() => {
        const { search } = location; 
        let queryParams = search.split('?')[1].split('&'); 
        console.log(queryParams); 
        for(let param of queryParams) {
            if(param.indexOf('accessKey') !== -1) {
                console.log("publicKey", param)
                setPublicKey(param.split('accessKey=')[1])
            } else if(param.indexOf('orderToken') !== -1) {
                setParams(param.split('orderToken=')[1])
            } else if(param.indexOf('orderId=') !== -1) {
                setParams(param.split('orderId=')[1])
            } else if(param.indexOf('urlSuccess') !== -1) {
                setUrlSuccess(param.split('urlSuccess=')[1])
            } else if(param.indexOf('urlError') !== -1) {
                setUrlError(param.split('urlError=')[1])
            }
        }
    },[setParams]); 

    useEffect(() => {
        if(publicKey !== '' && params !== '') {
            const getOrder = async () => {
                let headers = new Headers();
                headers.set('Authorization', 'Basic YWRtaW46c3VwZXJzZWNyZXQ=');
                headers.set('Content-Type', 'application/json')
                console.log('gh', process.env.URL_BASE)
                let response = await fetch(`https://sandbox-sf-api.bankaya.com.mx/api/v1/orders/?accessKey=${publicKey}&orderToken=${params}`,{ headers }).then(x => x.json());
                console.log(response)
                if(response.orderToken === undefined) {
                    setError({status: true, message: response.error_description });
                    return; 
                }
                const { emailConfirmation, amount, itemDescription, orderToken, orderId, productId, providerId } = response; 
                console.log(amount, orderToken, productId)
                sessionStorage.setItem('description', itemDescription);
                sessionStorage.setItem('amount', amount);
                setProducts([{
                    id: productId,
                    provider: providerId, 
                    price: amount,
                    count: 1
                }])
                setEmail(emailConfirmation);
                sessionStorage.setItem('orderToken', orderToken);
                sessionStorage.setItem('orderId', orderId)
                return response; 
            }
            getOrder(); 

        }
    }, [params, publicKey])

    useEffect(() => {
        console.log(products)
        if(products.length !== 0) {
            setCart({
                ...cart,
                products
            })
        }
    }, [products])

    if(error.status) {
        return(
            <div id="error_modal_iframe" style={{height: "90vh"}} className="w-100 d-flex justify-content-center align-items-center">
                <div className="col-10 col-md-7 mx-auto mt-5">
                    <div className="p-3">
                        <p>Ocurrió un Error</p>
                        <p className="alert alert-danger" role="alert">{error.message}</p>
                        <button className="w-100 btn btn-danger" onClick={() => window.top.location.href = window.top.location.href }>Continuar...</button>
                    </div>
                </div>
            </div>
        )
    }

    return ( 
        <Cart urlRedirect={{ urlSuccess,urlError }} />
    );
}
 
export default CartIframe;