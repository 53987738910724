import React from 'react';
import { ButtonLink } from './common/index.styled';

const CartFloat = ({ cart }) => {
    const Button = cart.products.length === 0 ? "No hay productos en tu carrito" 
    : <ButtonLink className="secondary_button w-100 w-md-50 d-flex justify-content-center align-items-center ml-auto" to="/cart_initial">Comprar</ButtonLink>;
    return ( 
        <div className="cart_float">
            <div className="content">
                {cart.products.map(product => (
                    <div key={product.productId} className="row w-100 m-0 cart_item_float mb-3 align-items-center justify-content-between">
                        <div className="d-flex col-9 p-0">
                            <div className="cart_item_image d-flex align-items-center">
                                <img src={product.productImage} alt={product.productName} className="img-fluid" />
                            </div>
                            <div className="d-flex align-items-center ml-2 col-md-9 col-7">
                                <div className="text-left w-100">
                                    <p className="count">{product.count > 1 ? `${product.count} piezas` : `${product.count} pieza`}</p>
                                    <p className="product_name">{product.productName}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 text-right p-0">
                            <p className="price">{product.count > 1 ? `${product.count} x ` : null}${parseFloat(product.price).toFixed(2)}</p>
                        </div>
                    </div>
                    )
                )}
            </div>
            <div className="button_cart">
                {Button}
            </div>
        </div>
     );
}
 
export default CartFloat;